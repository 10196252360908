import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { UserInfo } from '../../../shared/entities/UserInfo';

interface Transaction {
	isRedeemed: boolean
}

interface RewardWallet {
	details: string;
	name: string;
	walletCode: number;
	expirationDate: string;
	id: number;
	rewardValue: number;
}

interface TimeLineItem {
	transactionDate: string;
	expirationDate: string;
	activity: string;
	brand: string;
	conceptName: string;
	formattedDate: string;
	location: string;
	locationCode: string;
	transactionMonth: string;
	transactions: Transaction[];
}

export interface AppState {
	videoPlaying: boolean;
	auth0Token: string | null;
	userInfo: UserInfo | null;
	userRewardsInfo: {
		userPoints: number;
		userRewardsAmount: number;
		pointsUntilReward: number,
		maximumPointsLimit: number,
		rewardsWallets: RewardWallet[],
		userTimeline: TimeLineItem[],
	}
}

export const initialState: AppState = {
	videoPlaying: true,
	auth0Token: null,
	userInfo: null,
	userRewardsInfo: {
		userPoints: 0,
		userRewardsAmount: 0,
		pointsUntilReward: 0,
		maximumPointsLimit: 0,
		rewardsWallets: [],
		userTimeline: [],
	}
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setVideoPlaying: (state, { payload }: PayloadAction<AppState['videoPlaying']>) => {
			state.videoPlaying = payload;
		},
		setAuth0Token: (state, { payload }: PayloadAction<AppState['auth0Token']>) => {
			state.auth0Token = payload;
		},
		setUserInfo: (state, { payload }: PayloadAction<AppState['userInfo']>) => {
			state.userInfo = payload;
		},
		setUserRewardsInfo: (state, action: PayloadAction<AppState['userRewardsInfo']>) => {
			state.userRewardsInfo = action.payload
		},
		resetApp: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => ({
			...state,
			...action,
		}));
	},
});
